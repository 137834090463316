import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rstaffux-c7c0e8e2aua2a7fx.b01.azurefd.net/rStaffWeb',
  serviceEndPoints: [
    {
      clientId: '',
      serviceEndPoint: '',
    },
  ],
};
