import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle,
  ISharedComponentConfig, ISharedComponents, IAppServiceWithSharedComponent, IAuthExceptionServiceEndPoints } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { environment } from './app/common/services/config/environmentHelper'
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class StaffingWebApplication_routes implements IAppService, IAppServiceWithSharedComponent {

    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
        const configureWorkflowsRoute: IStateConfig = {
          name: 'grm-configure-workflows',
          url: '/configure-workflows',

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
          data: {
            headerName: 'Configure Workflows',
            breadcrumbText: 'Configure Workflows',
            pageTitle: 'Configure Workflows',
            partnerTelemetryName: 'ResourceStaffing',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.GrmConfigureWorkflows',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        };

        const serviceDeskActionRoute: IStateConfig = {
          name: 'servicedeskaction',
          url: '/servicedeskaction',

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
          data: {
            headerName: 'Service Desk Action',
            breadcrumbText: 'Service Desk Action',
            pageTitle: 'Service Desk Action',
            partnerTelemetryName: 'ResourceStaffing',
          }
        };

        const proximityCostRoute: IStateConfig = {
          name: 'grm-proxmity-cost',
          url: '/proximity-costs',

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
          data: {
            headerName: 'Update Proximity Cost',
            breadcrumbText: 'Update Proximity Cost',
            pageTitle: 'Update Proximity Cost',
            partnerTelemetryName: 'ResourceStaffing',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.GrmConfigureWorkflows',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        };


        //To DO: Below test component to be deleted later

        const testRoute: IStateConfig= {
          name: 'ux-test',
          url: '/rstafftest',

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
          data: {
            headerName: 'Test',
            breadcrumbText: 'Test',
            pageTitle: 'Test',
            partnerTelemetryName: 'ResourceStaffing',
            ocvAreaName: 'GCE_Requests',
          },
        };
        const resourceSearchRoute: IStateConfig= {
          name: 'grm-resource-search',
          url: '/resource-search',

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
          data: {
            headerName: 'Resource Search',
            breadcrumbText: 'Resource Search',
            pageTitle: 'Resource Search',
            partnerTelemetryName: 'ResourceStaffing',
          },
          authorizationRules: {
            AllowedPermissions: {
              ResourcePermissions: {
                Value: [
                  {
                    Resource: 'FxPPlatform.OBO',
                    Permissions: { Value: ['All'] },
                  },
                ],
              },
            },
          },
        };

        const cceAccountDetailsRoute: IStateConfig={
          name: 'cce-accountdetails',
          url: '/account-details',

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
          data: {
            headerName: 'My Requests',
            breadcrumbText: 'My Requests',
            pageTitle: 'My Requests',
            partnerTelemetryName: 'ResourceStaffing',
            ocvAreaName: 'GCE_Requests',
          },
        };

        const rstaffjobboardNewRoute: IStateConfig = {
          name: "rstaff-jobboard",
          url: "/jobboard?view",

generatedBundle: '4cda3e852e6d-RstaffJobboardModule-Bundle',
          data: {
            headerName: "Job Board",
            breadcrumbText: "Job Board",
            pageTitle: "Job Board",
            partnerTelemetryName: "ResourceStaffing",
            partnerAppName: "ResourceStaffing",
            extendable: {
              webComponent: "rsw-jb-page",
              props: {
                commentsOnExpressInterest: true,
                accreditationWithSkill: true,
              },
            },
          },
        };


        const routeInfo: IRouteInfo = {
          applicationName: "staffingWebApplication",
          sharedBundles: [],
          routes: [
            testRoute,
            resourceSearchRoute,
            cceAccountDetailsRoute,
            configureWorkflowsRoute,
            proximityCostRoute,
            rstaffjobboardNewRoute,
            serviceDeskActionRoute
          ],
        };
      return routeInfo;
    }

    getServiceEndPoints(): Array<IServiceEndPoints> {
      const endpoints: IServiceEndPoints[] = environment().ServiceEndPoints
      return endpoints
    }

    getAuthExceptionEndpoints(): IAuthExceptionServiceEndPoints[] {
      const authExceptionEndpoints: IAuthExceptionServiceEndPoints[] =  [{ serviceEndPoint: 'assets/config.json' }]
      return authExceptionEndpoints;
    }

    getBundles(): IPartnerBundle[] {
      const baseUrl = appSettings().cdnBaseUrl;
      const bundle: IPartnerBundle = {
        name: 'staffing-app-bundle',
        files: [

          `${baseUrl}/RStaffCommonWebModule.vendor.bundle.js`,
          `${baseUrl}/RStaffStaffingXpModule.vendor.bundle.js`,
          `${baseUrl}/styles.bundle.js`
        ],
        sequentialLoading: true,
      };

      return [bundle];
    }

    getSharedComponentsInfo(): ISharedComponents {
      const resourceInfoCardSharedComp: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffCommonWebModule-Bundle',
        componentDescription:
          'This component renders a resource information card.',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'resource-info-card',
      }

      const resourcesearchSharedComp: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders a resource search data.',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'resource-search-tab',
      }

      const csaGenerateRequestComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription:
          'This component renders generate cce requests component.',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'cce-generate-request',
      }

      const cceswarmedcsasComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders cce-swarmed-csas',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'cce-swarmed-csas',
      }

      const ccestaffrequestComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders cce-staff-request',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'cce-staff-request',
      }

      const staffrequestComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders requests component.',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'staff-request',
      }

      const approveForSdComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders approve for service delivery popup',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'cce-approve-for-sd',
      }

      const WbJobboardPageComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RstaffJobboardModule-Bundle',
        componentDescription:
          "This component renders the MCS Job board Experience",
        componentInputs: [],
        componentOutputs: [],
        componentName: "OpVergeJobboardPage",
      };

      const mcsProjectRequestBaseComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders project request hierarchy with actions',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-project-request-base',
      }

      const mcsWorkforceComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders workforce for a given request',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-workforce',
      }

      const mcsRequestedSkills: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders skills for a given request',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-requested-skills',
      }

      const mcsApproveProposalComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders approve action component for requestor',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-approve-proposal',
      }

      const mcsRejectProposalComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders reject action component for requestor',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-reject-proposal',
      }

      const mcsGlobalSearchWorkforceComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders resource search',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'mcs-global-resource-search',
      }

      const rmwSingleRequestBaseComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders request details in list with detail layout.',
        componentInputs: [], // optional: Provide the list of input parameters with description.
        componentOutputs: [], // optional: Provide the list of output parameters with description.
        componentName: 'rmwb-single-request-base',
      }

      const rmwCommandBarComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders command bar for user to perform various actions.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-command-bar',
      }

      const rmwbGlobalSearchTalent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders command bar for user to perform various actions.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-global-search-talent',
      }
      const rmwProjectRequestComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders project request details in a grid layout.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-project-request-base',
      }

      const rmwDemandViewComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders demand view of a project.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-demand-view',
      }

      const rmwContextMenuComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders a context menu for the rm workbench.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-context-menu',
      }

      const wbListViewComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders Request details in a list view.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'wb-list-view',
      }

      const rmwbActionPanelComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component renders command bar vertically for user to perform various actions.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-action-panel',
      }

      const quickMatchSummaryComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders quick match summary for logged in resource.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'qm-talent-summary',
      }

      const cceAssignedToMeComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component renders gcxp requests assigned to me.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'cce-assigned-to-me-requests',
      }

      const rmwbRefreshRequestComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component is used to sync request status in azure search with db.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-refresh-request-status',
      }

      const breEditorComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component is used to author bre rules.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'bre-editor',
      }

      const csamDispatchSuggestionsComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component is used to view resource search results.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'csam-suggestions',
      }

      const rmwbSearchWorkforceAction: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component is used to view talent recommendation or perform custom search.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-search-workforce-action',
      }

      const rmWbProcessSwapTalentComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component is used to process talent swap maintenance.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'rmwb-process-swap-talent',
      }

      const cesScheduleSplitComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
        componentDescription: 'This component is used by CSAs to split their schedule.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'ces-schedule-split-shared',
      }

      const adminActionComponent: ISharedComponentConfig = {

generatedBundle: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
        componentDescription: 'This component is used by CSAs to split their schedule.',
        componentInputs: [],
        componentOutputs: [],
        componentName: 'admin-action',
      }

      return {
        appName: "staffingWebApplication",
        components: [
          resourceInfoCardSharedComp,
          resourcesearchSharedComp,
          csaGenerateRequestComponent,
          cceswarmedcsasComponent,
          ccestaffrequestComponent,
          approveForSdComponent,
          staffrequestComponent,
          mcsProjectRequestBaseComponent,
          mcsWorkforceComponent,
          mcsRequestedSkills,
          mcsApproveProposalComponent,
          mcsRejectProposalComponent,
          mcsGlobalSearchWorkforceComponent,
          rmwSingleRequestBaseComponent,
          rmwCommandBarComponent,
          rmwProjectRequestComponent,
          rmwContextMenuComponent,
          wbListViewComponent,
          rmwbGlobalSearchTalent,
          rmwbActionPanelComponent,
          rmwDemandViewComponent,
          quickMatchSummaryComponent,
          cceAssignedToMeComponent,
          rmwbRefreshRequestComponent,
          breEditorComponent,
          csamDispatchSuggestionsComponent,
          WbJobboardPageComponent,
          rmwbSearchWorkforceAction,
          rmWbProcessSwapTalentComponent,
          cesScheduleSplitComponent,
          adminActionComponent
        ],
        disableSharing: false,
        sharedBundles: ["staffing-app-bundle"], // Bundle name should be same as application bundle name
      };
    }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: '4cda3e852e6d-RStaffAdminXpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RStaffAdminXpModule.vendor.bundle.js',
                    baseUrl + '/RStaffAdminXpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: '4cda3e852e6d-RStaffStaffingXpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RStaffStaffingXpModule.vendor.bundle.js',
                    baseUrl + '/RStaffStaffingXpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: '4cda3e852e6d-RstaffJobboardModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RstaffJobboardModule.vendor.bundle.js',
                    baseUrl + '/RstaffJobboardModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: '4cda3e852e6d-RStaffCommonWebModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RStaffCommonWebModule.vendor.bundle.js',
                    baseUrl + '/RStaffCommonWebModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(StaffingWebApplication_routes, 'staffingWebApplication');
