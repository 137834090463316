import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://cdne-rstaff-ux-aurorasit.azureedge.net/rStaffWeb',
  serviceEndPoints: [
    {
      clientId: '',
      serviceEndPoint: '',
    },
  ],
};
