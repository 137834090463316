import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://rstaffuxuat-hudygfc4b8axf0cd.b01.azurefd.net/rStaffWeb',
  serviceEndPoints: [
    {
      clientId: '',
      serviceEndPoint: '',
    },
  ],
};
